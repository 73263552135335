html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(236, 236, 236);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1976d2;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1976d2;
}

::-webkit-scrollbar:horizontal {
  height: 6px;
}

::-webkit-scrollbar-thumb:horizontal {
  border-radius: 10px;
  background-color: #1976d2;
}

::-webkit-scrollbar-track:horizontal {
  box-shadow: inset 0 0 5px rgb(236, 236, 236);
  border-radius: 10px;
}

.css-1o6q3tw-MuiFormLabel-root-MuiInputLabel-root {
  left: 6px !important;
}

